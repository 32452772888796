import React from 'react';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { supabase } from '../util/supabaseClient';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const SignInPopup = ({ show, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <Dialog open={show} onClose={onClose} aria-labelledby="sign-in-dialog" >
      <DialogContent>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Auth
          supabaseClient={supabase}
          appearance={{ 
            theme: ThemeSupa, 
            style: {
              button: {
                backgroundColor: 'blue',
                color: 'white',
                border: '0px solid',
              },
            },
          }}
          providers={[]} // Add other providers as needed
          onlyThirdPartyProviders={false} // Set to false to include email/password option
        />
      </DialogContent>
    </Dialog>
  );
};

export default SignInPopup;
