import React, { useState } from 'react';
import { Box, TextField, IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';

const MessageForm = ({ onSendMessage, isSending, user, setShowSignIn }) => {
  const [message, setMessage] = useState('');

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   onSendMessage(message);
  //   setMessage('');
  //   // if (!user) {
  //   //   setShowSignIn(true);  // Trigger sign-in popup if the user isn't logged in
  //   // } else if (message.trim()) {
  //   //   onSendMessage(message);
  //   //   setMessage('');
  //   // }
  // };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!message.trim()) return; // Don't send empty messages
    onSendMessage(message);
    setMessage('');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      handleSubmit(event);
    }
  };


  return (
    <Box component="form" sx={{ display: 'flex', alignItems: 'center', p: 1 }} onSubmit={handleSubmit}>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Type your message..."
        multiline
        rows={4}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        sx={{ mr: 1 }}
        disabled={isSending}
      />
      <IconButton color="primary" type="submit" disabled={isSending} sx={{ p: '10px' }}>
        {isSending ? (
          <CircularProgress size={24} />
        ) : (
          <SendIcon />
        )}
      </IconButton>
    </Box>
  );
};

export default MessageForm;
